import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormFieldInterface, TestingAttributesType } from 'components/Form/Form.types';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SlotComponentProps } from '@mui/base/utils';
import iconBold from 'assets/icons/iconBold.svg';
import iconBullet from 'assets/icons/iconBullet.svg';
import iconItalics from 'assets/icons/iconItalics.svg';
import iconLink from 'assets/icons/iconLink.svg';
import iconUnderline from 'assets/icons/iconUnderline.svg';
import DateSelectIcon from 'components/Icon/DateSelectIcon';
import { Div } from 'components/Elements/Div/Div';
import Checkbox from 'components/Checkbox/Checkbox';
import { convertHTMLStringToEditorState, ISO_DATE_FORMAT } from 'utils/formatting';
import { RequiredAsterisk } from 'components/RequiredeAsterisk/RequiredAsterisk';
import { datePickerFormat } from 'utils/dates';
import TimezoneSingleSelect from '../DropdownSelect/SingleSelectDropdown/TimezoneSingleSelect';
import { IconButton, InputAdornment } from '@mui/material';
import {
  RichTextWithUserMention,
  RichTextWithUserMentionHandle,
} from 'components/RichText/UserMention';
import { Mention } from 'utils/userTagging';
import { useGetUsersQuery } from 'hooks/users/queries/useGetUsersQuery';
import { UserTaggingInfo } from 'components/UserTagging/UserListPicker';
import { TargetType } from '__generated__/graphql';

const CheckboxContainer = styled.div<{ $disabled?: boolean }>`
  text-align: left;
  margin-left: -0.5rem;
  ${(props) => props.$disabled && `color: ${props.theme.colors.disabled}`};
  ${(props) => !props.$disabled && 'cursor: pointer;'}
  display: flex;
  align-items: center;
`;

export const FieldLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.125rem;
  margin-top: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
`;

export const RichTextContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  .rdw-editor-wrapper {
    border: ${({ theme }) => `0.0625rem solid ${theme.colors.borderColor}`};
    border-radius: 0.625rem;
    width: 100%;
    padding-top: 0.3125rem;

    .rdw-editor-toolbar {
      border-bottom: ${({ theme }) => `0.0625rem solid ${theme.colors.borderColor}`};
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: ${({ theme }) => theme.colors.offWhite};
      padding-top: 0;
      padding-bottom: 0;

      .rdw-inline-wrapper,
      .rdw-link-wrapper,
      .rdw-list-wrapper {
        margin-bottom: 0;
      }

      .rdw-option-active {
        box-shadow: none;
        background-color: ${({ theme }) => theme.colors.blue300};
      }

      .rdw-option-wrapper {
        ${(props) => props.$disabled && 'pointer-events: none'};
        margin-top: -0.3125rem;
        border: none;
        padding-right: 0;
        border-radius: 0.125rem;

        img {
          background-color: transparent;
        }

        &:hover {
          box-shadow: none;
          background-color: ${({ theme }) => theme.colors.neutral300};
        }
      }

      .rdw-inline-wrapper {
        &:first-child {
          .rdw-option-wrapper {
            &:nth-child(3) {
              border-right: ${({ theme }) => `0.0625rem solid ${theme.colors.borderColor}`};
              padding-right: 1rem;
            }
          }
        }

        &:nth-child(2) {
          .rdw-option-wrapper {
            &:nth-child(2),
            &:nth-child(3) {
              padding-left: 0;
              margin-left: 0;
            }
          }
        }
      }

      * {
        background-color: ${({ theme }) => theme.colors.offWhite};
        border-color: ${({ theme }) => theme.colors.borderColor};
      }

      .rdw-dropdown-carettoopen {
        border-top: ${({ theme }) => `0.375rem solid ${theme.colors.black}`};
        border-left: 0.3125rem solid transparent;
        border-right: 0.3125rem solid transparent;
      }

      .rdw-dropdown-carettoclose {
        border-bottom: ${({ theme }) => `0.375rem solid ${theme.colors.black}`};
        border-left: 0.3125rem solid transparent;
        border-right: 0.3125rem solid transparent;
      }
    }

    .rdw-editor-main {
      ${(props) => props.$disabled && `color: ${props.theme.colors.disabled}`};
      min-height: 7rem;
      padding: 0 0.875rem;

      &:hover {
        cursor: text;
      }
    }
  }
`;

const TextFieldWrapper = styled(TextField)<{ $colorField?: boolean; $disabled?: boolean }>`
  input {
    ${(props) =>
      props.$colorField &&
      props.size !== 'small' &&
      `
        height: 5rem;
        width: 5rem;
    `}

    ${(props) => props.$disabled && `background-color: ${props.theme.colors.disabledBg};`}
  }
`;

const FormFieldWrapper = styled(Box)<{ $marginTop?: string }>`
  text-align: left;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface FormFieldComponentInterface {
  field: FormFieldInterface;
  fieldIndex?: number;
  onChange: (value: string, field: FormFieldInterface, key?: number, mentions?: Mention[]) => void;
  marginTop?: string;
  customVariant?: string;
}

const FormField = ({
  field,
  fieldIndex,
  onChange,
  marginTop,
  customVariant,
}: FormFieldComponentInterface) => {
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const finalError = useMemo(() => field.isValidOverride ?? error, [error, field.isValidOverride]);

  const { users } = useGetUsersQuery({});
  const userMentions: UserTaggingInfo[] = useMemo(
    () =>
      users.map((user) => ({
        userId: user.id,
        name: `${user.firstName} ${user.lastName}`,
        access: 'withAccess',
      })),
    [users]
  );

  const richTextRef = useRef<RichTextWithUserMentionHandle>();

  const setFocusOnEditor = () => {
    richTextRef.current?.focusEditor();
  };

  const handleFocus = useCallback(() => {
    setFocusOnEditor();
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const timeout = setTimeout(() => {
        if (field.realtimeValidator) {
          setError(!field.realtimeValidator(e.target.value));
        }
      }, 1000);

      onChange(e.target.value, field, fieldIndex);

      return () => clearTimeout(timeout);
    },
    [field, fieldIndex, onChange]
  );

  const label = field.label && (
    <LabelContainer>
      <div>
        {field.label}
        {field.requiredMinimumLength && field.requiredMinimumLength > 0 ? (
          <RequiredAsterisk />
        ) : null}
      </div>
      {field.tools}
    </LabelContainer>
  );

  if ((field.type === 'custom' && field.customField) || field.customField) {
    if (!field.customField) {
      throw new Error('Invalid form: type "custom" requires a customField be set');
    }
    return <FormFieldWrapper $marginTop={marginTop}>{field.customField}</FormFieldWrapper>;
  }

  if (field.richTextEditor) {
    // type casting is safe here because the conditional ensures that value will be a string if it is defined
    const defaultEditorState = convertHTMLStringToEditorState(field.value as string | undefined);
    return (
      <RichTextContainer $disabled={field.disabled}>
        {!field.containedLabel && <FieldLabel>{label}</FieldLabel>}
        <Editor
          readOnly={field.disabled}
          defaultEditorState={defaultEditorState}
          onChange={(e) => {
            onChange(draftToHtml(e), field, fieldIndex);
          }}
          toolbar={{
            options: ['blockType', 'inline', 'list', 'link'],
            blockType: {
              inDropdown: false,
              options: ['H1', 'H2', 'H3'],
            },
            inline: {
              options: ['bold', 'italic', 'underline'],
              bold: { icon: iconBold },
              italic: { icon: iconItalics },
              underline: { icon: iconUnderline },
            },
            list: {
              options: ['unordered', 'ordered'],
              unordered: { icon: iconBullet },
            },
            link: {
              options: ['link'],
              link: { icon: iconLink },
            },
          }}
        />
      </RichTextContainer>
    );
  }

  if (field.richTextWithUserMention) {
    return (
      <>
        <RichTextContainer $disabled={field.disabled}>
          {!field.containedLabel && <FieldLabel>{label}</FieldLabel>}
        </RichTextContainer>

        <Box
          // sx={{ pt: '1.6rem', pb: '2rem' }}
          component='div'
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            cursor: 'text',
            transition: 'border-color 0.1s ease-in-out',
            // width: 450,
            border: '0.0625rem solid rgb(202, 202, 202)',
            borderRadius: '0.625rem',
            height: '150px',
            overflowY: 'auto',
            position: 'relative',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => handleFocus()}
          onFocus={() => handleFocus()}
          // onBlur={handleBlur}
        >
          <RichTextWithUserMention
            ref={(ref) => {
              if (ref) richTextRef.current = ref;
            }}
            enableFormatting
            initialValue={field.value as string}
            forceValueUpdate={undefined}
            users={userMentions}
            taggedUsers={field.mentions ?? []}
            onChange={function (text: string, mentions: Mention[]): void {
              onChange(text, field, fieldIndex, mentions);
            }}
            targetType={TargetType.Activity}
          />
        </Box>
      </>
    );
  }

  if (field.type === 'checkbox') {
    return (
      <FormFieldWrapper $marginTop={marginTop} sx={field.sx}>
        <CheckboxContainer
          onClick={() => !field.disabled && field.onCheck && field.onCheck()}
          $disabled={field.disabled}
        >
          <Checkbox
            inputProps={field.inputProps as { [key: string]: string }}
            checked={!!field.checked}
            disabled={field.disabled}
          />
          {label}
        </CheckboxContainer>
      </FormFieldWrapper>
    );
  }

  if (field.type === 'date') {
    const dateTimeValue =
      typeof field.value === 'string'
        ? DateTime.fromFormat(field.value, ISO_DATE_FORMAT)
        : DateTime.fromJSDate(field.value as Date);

    return (
      <Div display='flex' width='100%' flexDirection='column' alignItems='flex-start'>
        {!field.containedLabel && <FieldLabel>{field.label}</FieldLabel>}
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            disabled={field.disabled}
            sx={{
              width: '100%',
              ...field.sx,
              marginTop: `${marginTop ? marginTop : field.containedLabel ? '1rem' : '0'}`,
            }}
            onChange={(e: DateTime | null) => {
              onChange(e?.toFormat(ISO_DATE_FORMAT) ?? '', field, fieldIndex);
            }}
            slots={{ openPickerIcon: DateSelectIcon }}
            label={field.containedLabel ? label : ''}
            slotProps={{
              textField: {
                ...field.fieldProps,
                ...field.inputProps,
                inputProps: { 'data-testid': 'date-input' },
              } as SlotComponentProps<typeof TextField, object, TestingAttributesType>,
              openPickerIcon: { ...field.iconProps } as SlotComponentProps<
                typeof DateSelectIcon,
                object,
                TestingAttributesType
              >,
            }}
            value={dateTimeValue.isValid ? dateTimeValue : null}
            format={datePickerFormat}
            className={customVariant}
          />
        </LocalizationProvider>
      </Div>
    );
  }

  if (field.type === 'timezone') {
    return (
      <FormFieldWrapper $marginTop={marginTop}>
        <FieldLabel>{label}</FieldLabel>
        <TimezoneSingleSelect
          {...field.fieldProps}
          label=''
          selectedTimezone={field.value?.toString() ?? ''}
          onChange={(value) =>
            handleChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>)
          }
          inputProps={field.inputProps as { [key: string]: string }}
          required={field.validator !== undefined || field.requiredMinimumLength !== undefined}
          containedLabel={true}
          marginTop={'0'}
        />
      </FormFieldWrapper>
    );
  }

  if (field.type === 'password') {
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <FormFieldWrapper $marginTop={marginTop}>
        {!field.containedLabel && <FieldLabel>{label}</FieldLabel>}
        <TextFieldWrapper
          {...field.fieldProps}
          value={field.value ?? ''}
          label={field.containedLabel && label}
          placeholder={field.placeholder ?? ''}
          className='password-input'
          onChange={handleChange}
          variant={field.variant ?? 'outlined'}
          fullWidth={field.fullWidth ?? true}
          type={showPassword ? 'text' : 'password'}
          sx={[
            {
              marginTop: field.containedLabel ? '1rem' : '0',
              border: '0',
            },
            ...(Array.isArray(field.sx) ? field.sx : [field.sx]),
          ]}
          disabled={field.disabled}
          helperText={finalError ? field.validationMessage : field.helperText}
          error={finalError}
          inputProps={field.inputProps as { [key: string]: string }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={togglePasswordVisibility} data-testid='show-password'>
                  {showPassword ? 'hide' : 'show'}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormFieldWrapper>
    );
  }

  return (
    <FormFieldWrapper $marginTop={marginTop}>
      {!field.containedLabel && <FieldLabel>{label}</FieldLabel>}
      <TextFieldWrapper
        {...field.fieldProps}
        value={field.value ?? ''}
        label={field.containedLabel && label}
        placeholder={field.placeholder ?? ''}
        onChange={handleChange}
        variant={field.variant ?? 'outlined'}
        fullWidth={field.fullWidth ?? true}
        type={field.type}
        inputProps={field.inputProps as { [key: string]: string }}
        sx={[
          {
            marginTop: field.containedLabel ? '1rem' : '0',
            border: '0',
          },
          ...(Array.isArray(field.sx) ? field.sx : [field.sx]),
        ]}
        $colorField={field.type === 'color'}
        $disabled={field.disabled}
        disabled={field.disabled}
        minRows={field.minRows ?? 3}
        multiline={field.multiLine}
        helperText={finalError ? field.validationMessage : field.helperText}
        error={finalError}
        className={customVariant}
      />
    </FormFieldWrapper>
  );
};

export default FormField;
